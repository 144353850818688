define(['app'], (app) => {

  const foundationFinderMultiBrandColourCard = () => {
    const component = {};

    component.config = {
      selectors: {
        closeModalButton: '[data-close-modal]',
      },
      channels: {
        closeModal: 'modal/close'
      }
    };

    component.init = (element) => {
      component.element = element;
      component.closeModalButton = element.querySelector(component.config.selectors.closeModalButton);
      component.bind();
    };

    component.bind = () => {
      component.closeModalButton.addEventListener('click', component.closeModal);
    };

    component.closeModal = () => {
      app.publish(component.config.channels.closeModal)
    }

    return component;
  };

  return foundationFinderMultiBrandColourCard;
});